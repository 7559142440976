import { CurrentTokenStatus } from '~/models/types';

const defaultValue: CurrentTokenStatus = {
  webEntryTokenId: 0,
  email: 'example@example.com',
  status: 'emailEntered',
  flowType: 'self',
  idOneDigit: 1,
  requiredBusinessFactCheck: false,
  companyVerificationAllowed: false,
};
const emailEntered: CurrentTokenStatus = {
  ...defaultValue,
  status: 'emailEntered',
};
const preBusinessFactCheckCompanyCreated: CurrentTokenStatus = {
  ...defaultValue,
  status: 'preBusinessFactCheckCompanyCreated',
};
const companyReviewed: CurrentTokenStatus = {
  ...defaultValue,
  status: 'companyReviewed',
};
const done: CurrentTokenStatus = {
  ...defaultValue,
  status: 'done',
};
const failedCompanyDuplication: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCompanyDuplication',
};
const failedCompanyNameIsTimee: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCompanyNameIsTimee',
};
const failedCompanyReviewed: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCompanyReviewed',
};
const failedCorporateNumberDuplication: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedCorporateNumberDuplication',
};
const failedTokenExpired: CurrentTokenStatus = {
  ...defaultValue,
  status: 'failedTokenExpired',
};

const statusList: CurrentTokenStatus['status'][] = [
  'emailEntered',
  'preBusinessFactCheckCompanyCreated',
  'companyReviewed',
  'done',
  'failedCompanyDuplication',
  'failedCompanyNameIsTimee',
  'failedCompanyReviewed',
  'failedCorporateNumberDuplication',
  'failedTokenExpired',
];

export const currentTokenStatus = (pattern: number): CurrentTokenStatus => ({
  webEntryTokenId: pattern,
  email: `example+${pattern}@example.com`,
  status: statusList[statusList.length % pattern],
  flowType: 'self',
  idOneDigit: pattern % 10,
  requiredBusinessFactCheck: pattern % 2 === 0,
  companyVerificationAllowed: pattern % 2 === 0,
});

currentTokenStatus.default = defaultValue;
currentTokenStatus.emailEntered = emailEntered;
currentTokenStatus.preBusinessFactCheckCompanyCreated = preBusinessFactCheckCompanyCreated;
currentTokenStatus.companyReviewed = companyReviewed;
currentTokenStatus.done = done;
currentTokenStatus.failedCompanyDuplication = failedCompanyDuplication;
currentTokenStatus.failedCompanyNameIsTimee = failedCompanyNameIsTimee;
currentTokenStatus.failedCompanyReviewed = failedCompanyReviewed;
currentTokenStatus.failedCorporateNumberDuplication = failedCorporateNumberDuplication;
currentTokenStatus.failedTokenExpired = failedTokenExpired;
